<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="p-4 bg-white" v-loading="loading">
				<div class="row">
					<div class="col-md-4">
						<h3 style="font-size: 21px" class="mt-2 font-semibold">
							{{ product.description }}
						</h3>
						<div class="bg-white single-product-img mt-3">
							<el-carousel :interval="5000" indicator-position="none"
										 v-if="product.images">
								<el-carousel-item v-for="(item, i) in product.images" :key="i">
                                    <span>
                                        <img class="carousel-cell-image"
											 :src="item.image" data-zoomable="">
                                    </span>
								</el-carousel-item>
							</el-carousel>
						</div>

					</div>
					<div class="col-md-5 mt-5 mb-10">
						<div class="bg-white flex flex-col space-y-10">
							<div v-if="product.product_long_description !== 'nan'">
								<div
									v-html="cleanMessage(cleanData(product.product_long_description))"></div>
							</div>
							<div v-else></div>
						</div>
					</div>
					<div class="col-md-3 flex flex-col justify-between">
						<div class="flex flex-col space-y-3 bg-white mt-5">
							<div>
								<h2 class="text-sm font-semibold">{{ product.description }}</h2>
							</div>
							<p>{{ $t('articleNumber') }}: {{ product.article_number }}</p>
							<div class="flex space-x-4 items-center"
								 v-if="GET_COMPANY_SETTING.hide_stock_quantity === false">
								<span class="block">{{ $t('disposableQty') }}: {{
										product.quantity_in_stock
									}}</span>
							</div>
							<br>
							<p class="italics"><i>{{ $t('Delivery') }}: {{ product.delivery_time }}
								{{ $t('days') }}</i></p>
							<p class="italics" v-if="product.estimated_delivery_date !== ''">
								<i>{{ $t('estimatedDeliveryDate') }}: {{
										product.estimated_delivery_date
									}}</i></p>
							<div v-if="hasDiscount() === false">
								<p class="price">{{ parseAmount(product.sales_price) }}{{
										product.currency
									}}</p>
							</div>
							<div v-else>
								<p class="price">{{ parseAmount(product.discounted_price) }}{{
										product.currency
									}}</p>
								<div class="div-discount-price">
									<span class="discounted-price mr-2">{{
											product.sales_price
										}}{{ product.currency }}</span>
									<span class="percentage-off" v-if="getPercentage() !== ''">{{
											getPercentage()
										}}%</span>
								</div>

							</div>

							<div v-if="product.hasOwnProperty('standard_price')">
								<div>
									<span class="font-semibold">{{ $t('standard_price') }}: {{
											product.standard_price
										}}</span>
								</div>
							</div>
							<div style="margin-bottom: 12px;" v-if="product.has_customer_resale_price">
								<div>
									<span class="font-semibold">{{
											product.consumer_resale_field_title
										}}</span>
								</div>
								<div class="position-relative">
									<p class="customer-resale-price font-semibold text-blue-500">
										{{ product.consumer_resale_amount }}:-
									</p>
								</div>
							</div>
							<div v-if="product.is_paired_product">
								<div class="m4">
									<span class="font-semibold">{{ $t('productPackageText') }}: {{
											product.product_package_bundle_number
										}}</span>
								</div>
							</div>
							<div v-if="product.has_customer_resale_price">
								<div class="">
									<span class="font-semibold">{{ $t('profitMargin') }}: {{
											calculateProfitMargin()
										}}</span>
								</div>
							</div>

							<div v-if="product.product_pdf" class="mt-3">
								<p class="text-base text-blue-600 ">
									<a :href="product.product_pdf" target="_blank" download class="flex"><img
										src="../../assets/images/icons/file.svg" class="cursor-pointer flex w-full"
										style="height: 18px; width: 18px" :alt="$t('productPdf')"/>
										<span>{{ $t('productPdf') }}</span>
									</a></p>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col mt-5">
					<h2 class="text-xl font-semibold">{{ $t('specification') }}</h2>
					<hr class="border-4 border-blue-800">
					<div :class="key % 2 === 1 ? 'bg-gray' : ''"
						 class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-2 mt-2"
						 v-for="(item,key) in product.specifications" :key="key">
						<p class="text-gray-500">{{ item.title }}</p>
						<p class="text-gray-500">{{ item.text }}</p>
					</div>

					<div
						v-if="product.hasOwnProperty('article_accessories') && product.hasOwnProperty('recommended_article')">
						<hr class="border-4 border-blue-800 mb-3"
							v-if="product.article_accessories.length > 0 || product.recommended_article.length > 0">
					</div>
				</div>
				<div
					v-if="product.hasOwnProperty('article_accessories') && product.hasOwnProperty('recommended_article')">
					<div class="row"
						 v-if="product.article_accessories.length > 0 || product.recommended_article.length > 0">
						<div
							:class="{'col-md-7': product.article_accessories.length > 0 &&  product.recommended_article.length > 0,
										 'col-md-12': product.article_accessories.length <= 0 &&  product.recommended_article.length > 0}">
							<div class="bg-white recommend-br">
								<div class="recommended-product-headline ">
									<p class="headline-text">{{ $t('recommendedProduct') }}</p>
								</div>
								<recommended-product :products="product.recommended_article" :user_type="'seller'"/>
							</div>

						</div>
						<div :class="{'col-md-5':product.article_accessories.length > 0 &&  product.recommended_article.length > 0,
										 'col-md-12': product.article_accessories.length > 0 &&  product.recommended_article.length <= 0}">
							<div class="bg-white recommend-br">
								<div class="recommended-product-headline ">
									<p class="headline-text">{{ $t('accessories') }}</p>
								</div>
								<recommended-product :products="product.article_accessories" :user_type="'seller'"/>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import RecommendedProduct from "../customer/component/RecommendedProduct";
export default {
	name: "articleDetailComponent",
	components:{
		RecommendedProduct
	},
	data: function () {
		return {
			product: {},
			loading: false,
			prevNum: 0,
			num: 0,
		}
	},
	computed:{
		...mapGetters({
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING'
		})
	},
	methods: {
		parseAmount(amount) {
			let am = String(amount).split('.')
			if (am.length > 0) {
				if (parseFloat(am[1]) === 0.0) {
					return am[0]
				} else {
					return amount
				}
			} else {
				return amount
			}
		},
		cleanMessage(message) {
			return message
		},
		getCompanyInfo() {
			this.$store.dispatch('portal/getCompanyDetails')
		},
		getArticles() {
			this.loading = true
			this.$store.dispatch('portal/getSingleArticle', {url: `/${this.$route.params.article_id}/`})
				.then(resp => {
					this.loading = false
					// const images = [
					// 	...document.querySelectorAll('[data-zoomable]'),
					// ]
					// mediumZoom(images, {
					// 	margin: 0,
					// 	'z-index': '999',
					// 	position: 'absolute',
					// 	background: 'transparent',
					// 	scrollOffset: 0,
					// })
					this.product = resp.data
				}).catch((err) => {
				this.loading = false
				window.Bus.$emit('output-error', err)
			})
		},
		hasDiscount() {
			let currentDate = moment().format('YYYY-MM-DD')
			if (this.product.scheduled_campaign_start_date !== null && this.product.scheduled_campaign_end_date !== null && this.product.scheduled_campaign_start_date !== '' && this.product.scheduled_campaign_end_date !== '') {
				let startDate = moment(this.product.scheduled_campaign_start_date).format('YYYY-MM-DD')
				let endDate = moment(this.product.scheduled_campaign_end_date).format('YYYY-MM-DD')
				if (currentDate >= startDate && currentDate <= endDate) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		getPercentage() {
			let discountedPrice = parseFloat(this.product.sales_price) - parseFloat(this.product.discounted_price)
			let total = (discountedPrice / parseFloat(this.product.sales_price)) * 100
			let percentile = Math.ceil(total)
			if (percentile) {
				return percentile
			} else {
				return ''
			}
		},
		cleanData: function (name) {
			if (['nan'].includes(name)) {
				return ''
			} else {
				return name
			}

		},
		calculateProfitMargin() {
			let net_revenue = parseFloat(this.product.consumer_resale_amount) - parseFloat(this.product.sales_price)
			let profit = parseFloat((parseFloat(net_revenue) / parseInt(this.product.consumer_resale_amount)) * 100).toFixed(2)
			return profit
		}
	},
	mounted() {
		this.getCompanyInfo()
		this.getArticles()
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.recommend-br {
	border: 1px solid #00000033;
	border-radius: 5px !important;
	padding-left: 10px;
	padding-right: 10px;
}


.form-control, .form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.total-summary {
	border-bottom: 1px solid #ddd;
	padding: 7px 0;
}

.el-loading-mask {

	.el-loading-spinner {
		display: unset !important;
	}
}

.el-check-out-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9999999999999999999999;
}

.eid-cancel-btn {
	margin-top: 11px !important;
	padding: 6px !important;
}

.btn-yellow {
	border: 1px solid #E8B105;
	color: #E8B105;
	font-weight: bold;
}

.price {
	font-size: 30px;
	color: #413683;
	font-weight: 600;
	text-align: center;
	margin-top: 20px;
}

.single-product-img .el-carousel__container {
	height: 15rem !important;
}

.number-incremental {
	top: 6px;
}

.bg-gray {
	background: rgba(217, 217, 217, 0.2) !important;
}

.div-discount-price {
	margin-top: 0px;
	font-size: 18px;
	text-align: center;

}

.discounted-price {
	color: #495057;
	text-decoration: line-through;
}

.percentage-off {
	color: red;
}

.customer-resale-price {
	position: absolute;
	right: 153px;
	font-size: 18px;
}

.medium-zoom-image {
	cursor: pointer;
	cursor: zoom-in;
	position: absolute;
	transition: transform .3s cubic-bezier(.2, 0, .2, 1) !important;
	z-index: 99
}

.recommended-product-headline {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 15px;
	font-weight: 500;
}

.recommended-product-headline > p {
	color: #4667A7 !important;

}
</style>
